import Vue from 'vue'
import App from './App.vue'
import router from './router'
import apolloProvider from './apollo_client'
import {DEBUG} from './settings'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import VueAxios from 'vue-axios'
import VueSocialauth from 'vue-social-auth'
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

Vue.use( VueSplide );

Vue.config.productionTip = DEBUG

Vue.use(VueAxios, axios);
Vue.use(VueSocialauth, {
  providers: {
    github: {
      clientId: process.env.VUE_APP_GITHUB_CLIENT_ID,
      redirectUri: 'https://www.haq.ai/auth/login' // Your client app URL
    }
  }
});

Vue.prototype.$s3path = 'https://haqai.s3.us-east-2.amazonaws.com/assets';

console.log(process.env.NODE_ENV);
Vue.prototype.$staticUrl = 'http://127.0.0.1:8000';

new Vue({
  router,
  apolloProvider,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')