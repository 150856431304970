import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import accounts from "./account";

Vue.use(Vuex);

const state = {
  authenticationStatus: null,
  updateChallengeObject: null,
  nextPreviousObject: localStorage.getItem('nextObj'),
  sideBarChallenge: null,
};


export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules: {
    accounts
  },
};
