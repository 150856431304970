import gql from "graphql-tag";

let USER_FIELDS = `
        dateJoined
        username
        firstName
        lastName
        email
        isStaff
        idNumber
        phone
        image
        id
        lastLogin
        pk
        eigenTokens
        socialAuth {
            edges {
                node {
                    uid
                    extraData
                    created
                    modified
                }
            }
}`

let USER_QUERY = gql`query{
    me {
        ${USER_FIELDS}
    }
}`
let REFRESH_QUERY = gql`
    mutation ($token: String!) {
        refreshToken(refreshToken:$token){
            token
            success
            refreshToken
        }
    }
`;
let REGISTER_QUERY = gql`
    mutation(
        $username: String!
        $email: String!
        $password1: String!
        $password2: String!
    ) {
        register(
            username: $username
            email: $email
            password1: $password1
            password2: $password2
        ) {
            success
            errors
        }
    }
`;
const LOGIN_QUERY = gql`
    mutation($email: String!, $password: String!) {
        tokenAuth(password: $password, email: $email) {
            token
            success
            errors
            refreshToken
            user {
                ${USER_FIELDS}
            }
        }
    }
`;

const VERIFY_ACCOUNT = gql`
    mutation($token: String!) {
    verifyAccount(token: $token) {
        success,
        errors
    }
    }
`

export {USER_QUERY, USER_FIELDS, REFRESH_QUERY, REGISTER_QUERY, LOGIN_QUERY, VERIFY_ACCOUNT}