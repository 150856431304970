import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/modules/homepage')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('@/modules/About')
    },
    {
        path: '/auth',
        name: 'auth',
        component: () => import('@/modules/auth'),
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import('@/modules/auth/components/login')
            },
            {
                path: 'register',
                name: 'Register',
                component: () => import('@/modules/auth/components/register')
            }
        ]
    },
    {
        path: '/password',
        name: 'AuthPassword',
        component: () => import('@/modules/auth/PasswordReset'),
        children: [
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: () => import('@/modules/auth/PasswordReset/_components/forgotpassword')
            },
            {
                path: '/password-reset/:token',
                name: 'ResetPassword',
                component: () => import('@/modules/auth/PasswordReset/_components/resetpassword')
            },
            {
                path: '/activate/:token',
                name: 'AccountActivation',
                component: () => import('@/modules/auth/PasswordReset/_components/accountActivation')
            }
        ]
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/modules/dashboard'),
        meta:{
          requiresAuth: true
        }
    },
    {
        path: '/challenges',
        name: 'Challenges',
        component: () => import('@/modules/challenges'),
        meta:{
          requiresAuth: true
        }
    },
    {
        path: '/challenge/overview/:id',
        name: 'ChallengeOverview',
        component: () => import('@/modules/AboutChallenge'),
        meta:{
            requiresAuth: true
        }
    },
    {
        path: '/challenge/:challengeId/',
        name: 'ProblemEditor',
        component: () => import('@/modules/Editor'),
        children: [
            {
              path: ':chapterId/:problemTitle/:problemId/:next?/:previous?',
              name: 'CodeEditor',
              component: () => import('@/modules/Editor/components/codeEditor'),
                meta:{
                    requiresAuth: true
                }
            }
        ]
    },
    {
        path: '/contribute',
        name: 'Contribute',
        component: () => import('@/modules/Contribute')
    },

    {
        path: '/terms&conditions',
        name: 'TermsConditions',
        component: () => import('../modules/termsAndConditions')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        store.dispatch('checkAuth').then(logged_in => {
            if (logged_in) {
                next()
            } else {
                next({
                    name: 'Login',
                    query: {nextUrl: to.fullPath}
                })
            }
        })

    } else {
        next()
    }
})
export default router
