let DEBUG = false;
// if (window.location.hostname === "127.0.0.1" || window.location.hostname === "localhost") {
//   DEBUG = true;
// }
let BACKEND_URL = "https://api.haq.ai/graphql";
let WS_URL = ""
if (DEBUG) {
  BACKEND_URL = "http://localhost:4000/graphql";
  WS_URL = "ws://localhost:4000/ws/graphql"
}

export { DEBUG, BACKEND_URL, WS_URL}