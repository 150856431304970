import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['~/assets/styles/variables.scss'],
  treeShake: true,
  theme: {
    themes: {
      light: {
        primary: '#6034bd',
      },
      dark: {
        primary: '#C1AFE9',
      },
      options: { customProperties: true },
    },
  },
});
