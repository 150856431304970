
const colorMxn = {
  data() {
    return {
      cardHeader: '#dbd7d779',
      ant: '#273142',
      dark: '#1f2735',
      modalColor: '#54657e',
      background: '#1f2735',
      primaryPink: '#C1AFE9',
      fbBlue: '#395185',
      grey1: '#f2f2f2',
      icon1: '#6034BD',
      icon2: '#CE21D3',
      icon3: '#3CC5FF',
      icon4: '#FF3B53',
      primaryBlue: '#1748CF',
    };
  },
  computed: {
    theme() {
      const result = this.$vuetify.theme.dark 
      return result;
    }
  },
  watch: {
    theme(val) {
      if(!val) {
        this.themeWhite();
        return;
      }
      this.themeDark();
    }
  },
  mounted() {
    this.theme ? this.themeDark() : this.themeWhite();
  },
  methods: {
    themeWhite() {
      this.grey1 = '#f2f2f2';
      this.icon1 = '#6034BD';
      this.icon2 = '#CE21D3';
      this.icon3 = '#3CC5FF';
      this.icon4 = '#FF3B53';
      this.primaryBlue = '#1748CF';
    },
    themeDark() {
      this.grey1 = '#333333';
      this.icon1 = '#CBBDEA';
      this.icon2 = '#EEB2F5'
      this.icon3 = '#5CCEFF';
      this.icon4 = '#FFADB7';
      this.primaryBlue = '#71B7F9';
    },    
  },
};

export default colorMxn;
