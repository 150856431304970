export default {
  setAccessToken(state, val) {
    state.token = val;
  },
  setRefreshToken(state, val) {
    state.refreshToken = val;
  },
  setNextPreviousObject(state, val) {
    state.nextPreviousObject = val;
  },
  setSideBarChallenge(state, val) {
    state.sideBarChallenge = val;
  }

};
