import Vue from "vue";
import ApolloClient from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import {createUploadLink} from 'apollo-upload-client'
import {BACKEND_URL} from './settings'
// import {WebSocketLink} from 'apollo-link-ws';
import {from} from 'apollo-link'
// import {getMainDefinition} from 'apollo-utilities'
import {createPersistedQueryLink} from "@apollo/client/link/persisted-queries";
// import { SubscriptionClient } from "subscriptions-transport-ws";
import {setContext} from "apollo-link-context";
import {sha256} from 'crypto-hash';


Vue.use(VueApollo);

const getAuth = (tokenName) => {
    if (typeof window !== 'undefined') {
        const token = window.localStorage.getItem(tokenName)
        if (token === 'null') {
        return '';
        }

        return token ? `Jwt ${token}` : '';
    }
}

const authMiddleware = setContext((_, {headers}) => {
    const authorization = getAuth("jwtToken")
    const authorizationHeader = authorization ? {authorization} : {}
    return {
        headers: {
            ...headers,
            ...authorizationHeader,
        },
    }
})

const httpLink = new createUploadLink({
    uri: BACKEND_URL,
    fetch,
});


let link = from([httpLink])


// let wsClient = new SubscriptionClient(WS_URL, {
//     reconnect: false,
//     connectionParams: () => {
//         const authorization = getAuth("token")
//         return authorization ? {authorization, headers: {authorization}} : {}
//     },
// })
// const wsLink = new WebSocketLink(wsClient);

link = authMiddleware.concat(link)
link = createPersistedQueryLink({sha256,useGETForHashedQueries: true}).concat(link)
// link = split(
//     ({query}) => {
//         const {kind, operation} = getMainDefinition(query)
//         return kind === 'OperationDefinition' &&
//             operation === 'subscription'
//     },
//     // wsLink,
//     link
// )

export const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
        addTypename: true
    }),
})

const apolloProvider = new VueApollo({
    defaultClient: client
})
export default apolloProvider
