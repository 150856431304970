<template>
  <v-app id="app">
    <Topbar @sidebar="showSidebar=!showSidebar" />
    <v-main>
      <router-view/>
    </v-main>

    <Footer/>
  </v-app>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';

export default {
  name: 'App',
  components: {
    Topbar: ()=> import('@/components/Topbar'),
    Footer: () => import('@/components/footer')
  },
  mixins: [colorMxn],
  data: () => ({
  }),
  mounted(){
    this.handleExpiry();
    this.$store.commit('getUser')
  },
  methods: {
    handleExpiry() {
      setInterval(() => {
        this.$store.dispatch('checkAuth');
      }, 10000);
      
    }
  }
};
</script>

<style lang="scss"> 
  @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
  @import "assets/styles/core.css";
  #app {
    font-family: Nunito,serif;
    font-style: normal;
    font-weight: normal;
  }
</style>

