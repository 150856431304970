import {client} from "../../apollo_client";
import {LOGIN_QUERY, REFRESH_QUERY, REGISTER_QUERY, USER_QUERY}
    from "../../queries/account";
import router from "../../router";

const state = {
    user: {},
    login_errors: [],
    register_errors: {},
    login_success: false,
    register_success: false,
    token: localStorage.getItem('jwtToken'),
    authLoading: false,
    tokenExpiredText: null,
    eigenTokens: 0,
}

const mutations = {
    getUser(state) {
        client.query({
            query: USER_QUERY
        }).then(res => {
            state.user = res.data.me;
            state.eigenTokens = res.data.me.eigenTokens;
        })
    },
    logout(state) {
        localStorage.clear();
        router.push({name: 'Login'});
        state.token = null,
        state.user = {}
        state.profile = {}
    },
    completeLogin(state, data) {
        state.token = data.token
        localStorage.setItem("jwtToken", data.token)
        let dt = new Date();
        dt.setMinutes(dt.getMinutes() + 30);
        localStorage.setItem("expiry", dt)
        localStorage.setItem("refresh_token", data.refresh_token)
        let path = router.history.current.query
        let nextUrl = "/dashboard"
        if (path.nextUrl) {
            nextUrl = path.nextUrl
        }
        router.push({path: nextUrl})
    },
    completeRefresh(state, data) {
        state.token = data.token
        localStorage.setItem("jwtToken", data.token)
        let dt = new Date();
        dt.setMinutes(dt.getMinutes() + 30);
        localStorage.setItem("expiry", dt)
        localStorage.setItem("refresh_token", data.refresh_token)
    },
    login(state, data_) {
        state.login_errors = {}
        state.authLoading = true;
        client.mutate({
            mutation: LOGIN_QUERY,
            variables: data_,
            update: (cache, {data}) => {
                if (!data.tokenAuth.errors) {
                    state.login_success = data.tokenAuth.success
                    let user = data.tokenAuth.user
                    state.user = user
                    let dt = {
                        "token": data.tokenAuth.token,
                        "refresh_token": data.tokenAuth.refreshToken,
                        "push": true,
                    }
                    this.commit("completeLogin", dt)
                } else {
                    state.login_errors = data.tokenAuth.errors.nonFieldErrors
                }
            }
        });
        state.authLoading = false;
    },
    register(state, data_) {
        state.register_errors = {}
        state.authLoading = true;
        client.mutate({
            mutation: REGISTER_QUERY,
            variables: data_,
            update: (cache, {data}) => {
                if (!data.register.errors) {
                    state.register_success = data.register.success
                } else {
                    state.register_errors = data.register.errors
                }
            },
        });
        state.authLoading = true;
    },
    refreshSessionToken() {
        let token = localStorage.getItem("refresh_token")
        client.mutate({
            mutation: REFRESH_QUERY,
            variables: {token: token},
            update: (cache, {data}) => {
                if (!data.refreshToken.success) {
                    this.commit('logout');
                }
                let dt = {
                    "token": data.refreshToken.token,
                    "refresh_token": data.refreshToken.refreshToken,
                    "push": false
                }
                this.commit("completeRefresh", dt)
                return;

                
            },
            error(error) {
                console.log(error);
                this.commit('logout');
            }
        })
    },
    setAuthLoading(state, val) {
      state.authLoading = val;
    },
    setEigenToken(state, val) {
        state.eigenTokens = val;
    },
}
const getters = {
    login_errors: state => {
        return state.login_errors
    },
    login_success: state => {
        return state.login_success
    },
    register_success: state => {
        return state.register_success
    },
    register_errors: state => {
        return state.register_errors
    },
    token: state => {
      return state.token
    },
    isAuthenticated: (state) => state.token !== null,
    user: (state) => state.user,
    getTokenExpiredText: (state) => state.tokenExpiredText,
    getEigenTokens: (state) => state.eigenTokens,
}

const actions = {
    checkAuth() {
        let token = localStorage.getItem("jwtToken")
        let expiry = localStorage.getItem("expiry")
        let now_ = new Date()
        let exp_time = new Date(expiry)
        if (exp_time > now_ && token) {
            
            return true
        } else {
            if (localStorage.getItem("refresh_token")) {
                this.commit('refreshSessionToken')
                return true
            } else {
                if (router.app.$route.matched.some(record => record.meta.requiresAuth)) {
                    state.tokenExpiredText = "Your Session has Expired!!, Kindly Login Again!!";
                    this.commit("logout")
                }
                return false
            }
        }
    },
}
export default {
    state, mutations, getters, actions
}
